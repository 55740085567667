import * as React from 'react';
import Typewriter from 'typewriter-effect';
import styles from './TypeWriter.module.css';

const TypeWriterEffect = ({ isMenuOpen }) => {
  return (
    <div className={styles.typeWriterContainer} style={{ marginLeft: isMenuOpen ? 100 : undefined }}>
      <Typewriter
        options={{
          strings: [
            'Hey, I wanna join the band!',
            'Hey, great to see you here',
            'Werk werk werk werk werk',
            'Wait, where’s your coffee?',
          ],
          autoStart: true,
          loop: true,
          wrapperClassName: styles.subText,
          cursorClassName: styles.typeWriterCursor,
        }}
      />
    </div>
  );
};

export default TypeWriterEffect;
