import * as React from 'react';
import Carousel from 'nuka-carousel';
import ProjectSummary from '../ProjectSummary/ProjectSummary';
import { projectData } from './projectData';
import { AiOutlineRightCircle, AiOutlineLeftCircle } from 'react-icons/ai';
import { motion } from 'framer-motion';
import styles from './ProjectCarousel.module.css';

const ProjectCarousel = () => {
  const carouselItems = projectData.map(project => <ProjectSummary project={project} key={project.name} />);

  const buttonSize = 40;
  const buttonColor = 'rgb(155, 155, 155)';
  const whileButtonHover = { cursor: 'pointer', scale: 1.1, opacity: 0.6 };

  const renderNextButton = nextSlide => {
    return (
      <motion.div whileHover={whileButtonHover}>
        <AiOutlineRightCircle size={buttonSize} color={buttonColor} onClick={nextSlide} />
      </motion.div>
    );
  };

  const renderPreviousButton = previousSlide => {
    return (
      <motion.div whileHover={whileButtonHover}>
        <AiOutlineLeftCircle size={buttonSize} color={buttonColor} onClick={previousSlide} />
      </motion.div>
    );
  };

  const renderControls = ({ nextSlide, previousSlide, slideCount, currentSlide }) => {
    return (
      <div className={styles.carouselControls}>
        {renderPreviousButton(previousSlide)}
        <span className={styles.slideCount}>
          {currentSlide + 1} / {slideCount}
        </span>
        {renderNextButton(nextSlide)}
      </div>
    );
  };

  return (
    <div className={styles.foo} id="project-carousel">
      <Carousel
        wrapAround
        speed={800}
        transitionMode="fade"
        // easing="easeQuad"
        enableKeyboardControls
        renderTopLeftControls={renderControls}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
      >
        {carouselItems}
      </Carousel>
    </div>
  );
};

export default ProjectCarousel;
