import * as React from 'react';
import styles from './ResumeBulletPoints.module.css';
import { AiOutlineHeart } from 'react-icons/ai';

const ResumeBulletPoints = ({ bullets }) => {
  return (
    <ul className={styles.resumeBulletPoints}>
      {bullets.map(bullet => (
        <li key={bullet}>
          <div>
            <AiOutlineHeart size={20} color="#848585" />
          </div>
          <span>{bullet}</span>
        </li>
      ))}
    </ul>
  );
};

export default ResumeBulletPoints;
