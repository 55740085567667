import * as React from 'react';
import styles from './ResumeHeader.module.css';
import { VscLocation } from 'react-icons/vsc';
import { MdOutlinePhoneIphone, MdDownloadForOffline } from 'react-icons/md';
import { AiOutlineMail } from 'react-icons/ai';
import { ImLinkedin } from 'react-icons/im';
import { BsGithub } from 'react-icons/bs';
import { motion } from 'framer-motion';
import ResumePdf from '../../docs/Resume - Saad Masood.pdf';

const ResumeHeader = () => {
  const iconSize = 20;
  const externalLinkIconSize = 30;

  const whileHoverExternalLink = { opacity: 0.6, cursor: 'pointer' };

  const openLinkInNewTab = link => {
    window.open(link, '_blank');
  };

  const onLinkedInClick = () => {
    openLinkInNewTab('https://www.linkedin.com/in/saad-masood-shaikh/');
  };

  const onGitHubClick = () => {
    openLinkInNewTab('https://github.com/saadmas');
  };

  const onDownloadClick = () => {};

  return (
    <div className={styles.resumeHeader}>
      <h1>Saad Masood</h1>

      <div className={styles.profileDetails}>
        <div className={styles.profileDetail}>
          <VscLocation size={iconSize} />
          New York, NY
        </div>
        <div className={styles.profileDetail}>
          <MdOutlinePhoneIphone size={iconSize} />
          929-343-9080
        </div>
        <div className={styles.profileDetail}>
          <AiOutlineMail size={iconSize} />
          <span className={styles.email}>smasood.sha@gmail.com</span>
        </div>
      </div>
      <div className={styles.externalLinks}>
        <motion.div className={styles.profileDetail} whileHover={whileHoverExternalLink}>
          <ImLinkedin size={externalLinkIconSize} onClick={onLinkedInClick} />
        </motion.div>
        <motion.div className={styles.profileDetail} whileHover={whileHoverExternalLink}>
          <BsGithub size={externalLinkIconSize} onClick={onGitHubClick} />
        </motion.div>
        <motion.div className={styles.profileDetail} whileHover={whileHoverExternalLink}>
          <a href={ResumePdf} download={true} style={{ textDecoration: 'none' }}>
            <MdDownloadForOffline size={externalLinkIconSize + 8} onClick={onDownloadClick} />
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default ResumeHeader;
