import * as React from 'react';
import { motion } from 'framer-motion';
import { BsArrowDownCircle } from 'react-icons/bs';
import styles from './ArrowDownIcon.module.css';
import { useNavigate } from 'react-router-dom';

const ArrowDownIcon = () => {
  const navigate = useNavigate();

  const onArrowDownClick = () => {
    navigate('/why-spotify');
  };

  return (
    <motion.div
      style={{ display: 'flex', justifyContent: 'center' }}
      initial={{ opacity: 0 }}
      transition={{ delay: 4 }}
      animate={{ opacity: 1 }}
    >
      <BsArrowDownCircle size={50} className={styles.arrowDownIcon} onClick={onArrowDownClick} />
    </motion.div>
  );
};

export default ArrowDownIcon;
