import * as React from 'react';
import { motion } from 'framer-motion';
import styles from './WhyWebEngineerToResume.module.css';
import { useNavigate } from 'react-router-dom';
import { BsArrowRightCircle } from 'react-icons/bs';

const WhyWebEngineerToResume = () => {
  const navigate = useNavigate();

  const goToResume = () => {
    navigate('/resume');
  };

  return (
    <div className={styles.whyWebEngineerToResume}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.5, staggerChildren: 1 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        <motion.div
          className="greenLine"
          style={{ margin: '0 auto' }}
          whileInView="visible"
          viewport={{ once: true }}
          variants={{
            visible: { width: '20%' },
            hidden: { width: 0 },
          }}
          transition={{ delay: 0.2, duration: 0.7 }}
        />
        <motion.div
          className={styles.whyWebEngineerToResumeText}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: -10 },
          }}
        >
          <div>Thank you for taking the time to read this.</div>
          <div>
            I'd love to continue the conversation—my contact details are listed on my resume.
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
              }}
              transition={{ delay: 1.5, duration: 0.6 }}
            >
              <BsArrowRightCircle size={40} className={styles.arrowRightIcon} onClick={goToResume} color="#1db954" />
            </motion.span>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default WhyWebEngineerToResume;
