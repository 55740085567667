import * as React from 'react';
import { motion } from 'framer-motion';
import styles from './EdmInterviews.module.css';
import EdmInterviewPhotoGallery from '../EdmInterviewPhotoGallery/EdmInterviewPhotoGallery';

const EdmInterviews = () => {
  return (
    <div className={styles.edmInterviews}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.5, staggerChildren: 1 }}
        variants={{
          visible: { opacity: 1, scale: 1, translateY: -200 },
          hidden: { opacity: 0, scale: 1 },
        }}
      >
        <h2 transition={{ delay: 0.5, duration: 0.7 }}>
          Plus, interviewed artists with trailblazing sounds and outspoken ambitions
        </h2>
        <EdmInterviewPhotoGallery />
      </motion.div>
    </div>
  );
};

export default EdmInterviews;
