import { motion } from 'framer-motion';
import * as React from 'react';
import JobRequirementsBreakdown from './components/JobRequirementsBreakdown/JobRequirementsBreakdown';
import MuscNotesAnimation from '../../components/MuscNotesAnimation/MuscNotesAnimation';
import styles from './WhyWebEngineerPage.module.css';
import SpotifyIconGreen from '../../assets/photos/spotify-icon-green.png';
import WhyWebEngineerToResume from './components/WhyWebEngineerToResume/WhyWebEngineerToResume';
import { Helmet } from 'react-helmet';

const WhyWebEngineerPage = () => {
  return (
    <>
      <Helmet>
        <title>Why Web Engineer?</title>
      </Helmet>
      <div className={styles.whyWebEngineerPage}>
        <div className={styles.spotifyIconContainer}>
          <motion.img
            src={SpotifyIconGreen}
            className={styles.spotifyIcon}
            whileHover={{ scale: 1.2 }}
            initial={{ opacity: 0, scale: 0, x: 0 }}
            animate={{ opacity: 1, scale: 1, x: 20 }}
            transition={{ delay: 0.7, duration: 0.7 }}
          />
        </div>
        <motion.h1
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          transition={{ delay: 0.5, duration: 0.7 }}
        >
          Daily Mix and Discover Weekly are some of my favorite Spotify features. A personalized Spotify is what keeps
          listeners (like me) particularly excited to open Spotify, welcomed by a host of stellar recommendations.
        </motion.h1>
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={{
            visible: { opacity: 1, color: '#1db954' },
            hidden: { opacity: 0, color: '#efd9ce' },
          }}
          transition={{ delay: 0.7, duration: 0.7 }}
        >
          More importantly, let me tell you why I have what it takes for the Web Engineer role on the Personalization
          team.
        </motion.h2>
        <MuscNotesAnimation note1 note2 note3 note4 />
        {/* <motion.div
        className="greenLine"
        style={{ margin: '0 auto' }}
        initial={{ width: 0 }}
        animate={{ width: '15%' }}
        transition={{ delay: 0.3, duration: 0.7 }}
      /> */}
        <JobRequirementsBreakdown />
        <WhyWebEngineerToResume />
      </div>
    </>
  );
};

export default WhyWebEngineerPage;
