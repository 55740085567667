import * as React from 'react';
import ConnectedRequirementExplanation from '../ConnectedRequirementExplanation/ConnectedRequirementExplanation';
import styles from './JobRequirementsBreakdown.module.css';

const requirementExplanationConfig = [
  {
    color: '#5F4263',
    requirement:
      'You have 2+ years of experience as a Front End or Full Stack Web Engineer, working within Javascript building internally-facing applications.',
    explanation: [
      "With a breadth of music-based apps and high-growth startup experience under my belt, I've been undertaking Full Stack Web development professionally and personally for over 2 years.",
      "What's more, at Catchpoint, I've built 2 internal, high-value applications (TypeScript based) which are still in use today",
    ],
  },
  {
    color: '#23357D',
    requirement:
      'You are a solutions-oriented team player. You are flexible and ready to do what‘s best for your users.',
    explanation: [
      'At my current company, alongside my squad lead, we reduced squad defects by 72% over 4 release cycles by developing and implementing a quality improvement process',
      'Another time—during a tech stack migration—rather than just sticking with an existing design, for the benefit of our users we redesigned our source selection UI from flat, table-based single selection to hierarchal, tree-based selection across 18+ source types',
    ],
  },
  {
    color: '#4500E6',
    requirement: 'You are eager to learn and expand your areas of expertise.',
    explanation: [
      'To keep up with the fast-paced world of web development, I subscribe to and eagerly explore technical newsletters like JavaScript Weekly, CSS Weekly, Pointer, React Digest and ui.dev.',
    ],
  },
  {
    color: '#648152',
    requirement: 'Experience with React, Typescript, or Java is ideal but not required.',
    explanation: [
      'In my career, I have been working with React and TypeScript every day for over 2 years.',
      '2 of my personally built web apps are crafted with React and TypeScript.',
      'Also, this website was created with React!',
    ],
  },
  {
    color: '#FF4632',
    requirement:
      'Build user-friendly web applications to enable flexible configuration and experimentation of new product features.',
    explanation: [
      'Currently, my squad at Catchpoint is integrating a newly acquired product into our existing web portal.',
      "This project involves creating intuitive analytics web interfaces that hide the complexity of backend systems and Catchpoint's proprietary NoSQL database",
      "Plus, since we're working on a Beta release, we're coding flexibly, keeping in mind that our users will provide feedback that may require refactoring.",
    ],
  },
  {
    color: '#EB132B',
    requirement:
      'Collaborate with designers, backend engineers, product managers, and data scientists to design and implement product requirements.',
    explanation: [
      'Going off the previous point, integrating a newly acquired product into an existing platform requires tons of cross-team collaboration to make sure we cover our bases cohesively.',
      'For me, that means thoroughly analyzing feature requirements with Product Managers, and Figma designs with UX Designers, to make sure we identify any gaps and clarify concerns from both engineering and usability standpoints.',
    ],
  },
  {
    color: '#333',
    requirement: 'Integrate with multiple backend and web APIs.',
    explanation: [
      'At Catchpoint, I build web interfaces integrated with external vendor APIs and various internal endpoints to fetch everything from user settings to fine-grained analytics data.',
      "Web apps I've built myself rely on a breadth of APIs—like Firebase, Spotify, EDM Train, and Chrome, to name a few.",
    ],
  },
];

const JobRequirementsBreakdown = () => {
  return (
    <div className={styles.jobRequirementsBreakdown}>
      {requirementExplanationConfig.map(props => (
        <ConnectedRequirementExplanation {...props} key={props.requirement} />
      ))}
    </div>
  );
};

export default JobRequirementsBreakdown;
