import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './NavigationMenu.module.css';
import { AiOutlineMenu } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const links = [
  { name: 'Why Spotify?', path: '/why-spotify' },
  { name: 'Why Web Engineer, Personalization?', path: '/why-web-engineer-personalization' },
  { name: 'Resume', to: '/resume' },
];

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: 1,
    },
  },
};

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
};

const NavigationMenu = ({ open, cycleOpen }) => {
  const navigate = useNavigate();

  const onNavItemClick = path => {
    navigate(path);
    cycleOpen();
  };

  return (
    <main>
      <AnimatePresence>
        {open && (
          <motion.aside
            initial={{ width: 0 }}
            animate={{
              width: 250,
            }}
            exit={{
              width: 0,
              transition: { delay: 0.5, duration: 0.1 },
            }}
          >
            <motion.div
              className={styles.container}
              initial="closed"
              animate="open"
              exit="closed"
              variants={sideVariants}
            >
              {links.map(({ name, to, path }) => (
                <motion.a
                  key={path + name}
                  href={to}
                  whileHover={{ scale: 1.1, x: 30 }}
                  variants={itemVariants}
                  onClick={() => onNavItemClick(path)}
                >
                  {name}
                </motion.a>
              ))}
            </motion.div>
          </motion.aside>
        )}
      </AnimatePresence>
      <motion.div
        style={{ position: open ? 'absolute' : undefined }}
        className={styles.btnContainer}
        initial={{ opacity: 0 }}
        transition={{ delay: 4 }}
        animate={{ opacity: 1 }}
        whileHover={{ opacity: 0.6 }}
      >
        <AiOutlineMenu onClick={cycleOpen} size={40} />
      </motion.div>
    </main>
  );
};

export default NavigationMenu;
