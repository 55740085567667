import { motion } from 'framer-motion';
import * as React from 'react';

const ExitAnimator = ({ children }) => {
  return (
    <motion.div key="resume" exit={{ translateY: -100, opacity: 0, transition: { duration: 0.3 } }}>
      {children}
    </motion.div>
  );
};

export default ExitAnimator;
