import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { edmArticles } from './edmArticles.js';
import styles from './EdmArticlesTimeline.module.css';
import { motion } from 'framer-motion';

const EdmArticlesTimeline = () => {
  const articleImageSize = 500;

  const openLinkInNewTab = link => {
    window.open(link, '_blank');
  };

  const renderTimelineElements = () => {
    const timelineElements = edmArticles.map((article, index) => {
      const key = article.url;
      return (
        <VerticalTimelineElement
          id={key}
          key={key}
          className={styles.timelineElement}
          onTimelineElementClick={() => openLinkInNewTab(article.url)}
          contentStyle={{ background: '#121212', boxShadow: 'none' }}
          // icon={getIcon(index)}
          iconStyle={{ boxShadow: 'none' }}
          contentArrowStyle={{ borderRight: `7px solid #121212` }}
        >
          <div className={styles.timelineCard}>
            <h3 className={styles.articleHeading}>{article.heading}</h3>
            <motion.img
              src={article.imageSrc}
              width={articleImageSize}
              height={articleImageSize}
              alt={article.heading}
              whileHover={{ scale: 1.1, y: 20 }}
            />
          </div>
        </VerticalTimelineElement>
      );
    });

    return timelineElements;
  };

  return (
    <section className={styles.timeline}>
      <VerticalTimeline lineColor="#333" className={styles.verticalTimeline}>
        {renderTimelineElements()}
      </VerticalTimeline>
      <motion.div
        className={styles.greyLine}
        whileInView="visible"
        viewport={{ true: false }}
        variants={{
          visible: { width: '20%' },
          hidden: { width: 0 },
        }}
        transition={{ delay: 0.2, duration: 0.7 }}
      />
    </section>
  );
};

export default EdmArticlesTimeline;
