import BenBohmer from '../../../../assets/photos/ben-bohmer.jpg';
import ChristianLoeffler from '../../../../assets/photos/christian-loeffler.jpg';
import Lane8 from '../../../../assets/photos/lane-8.jpg';
import JaiWolf from '../../../../assets/photos/jai-wolf.png';
import Nghtmre from '../../../../assets/photos/nghtmre.jpg';
import Tchami from '../../../../assets/photos/tchami.png';

export const edmInterviewPhotos = [
  {
    url: 'https://edm.com/interviews/ben-bohmer-hot-air-balloon-performance-interview',
    imageSrc: BenBohmer,
  },
  {
    url: 'https://edm.com/interviews/christian-l%C3%B6ffler-lys-interview',
    imageSrc: ChristianLoeffler,
  },
  {
    url: 'https://edm.com/interviews/lane-8-this-never-happened-brooklyn-mirage',
    imageSrc: Lane8,
  },
  {
    url: 'https://edm.com/interviews/jai-wolf-cure-loneliness-interview',
    imageSrc: JaiWolf,
  },
  {
    url: 'https://edm.com/interviews/nghtmre-terminal-5-nyc-interview',
    imageSrc: Nghtmre,
  },
  {
    url: 'https://edm.com/interviews/tchami-confession-tour-interview-nyc',
    imageSrc: Tchami,
  },
];
