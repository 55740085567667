import { motion } from 'framer-motion';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import ExitAnimator from '../../components/ExitAnimator/ExitAnimator';
import EdmInterviews from './components/EdmInterviews/EdmInterviews';
import WhySpotifyArticles from './components/WhySpotifyArticles/WhySpotifyArticles';
import WhySpotifyIntro from './components/WhySpotifyIntro/WhySpotifyIntro';
import WhySpotifyProjects from './components/WhySpotifyProjects/WhySpotifyProjects';
import WhySpotifyToWhyWebEngineer from './components/WhySpotifyToWhyWebEngineer/WhySpotifyToWhyWebEngineer';
import styles from './WhySpotifyPage.module.css';

const WhySpotifyPage = () => {
  return (
    <>
      <Helmet>
        <title>Why Spotify?</title>
      </Helmet>
      <ExitAnimator>
        <motion.div
          className={styles.whySpotifyPage}
          // initial={{ background: '#cff56a' }}
          // animate={{
          //   background: '#121212',
          // }}
        >
          <WhySpotifyIntro />
          <WhySpotifyProjects />
          <WhySpotifyArticles />
          <EdmInterviews />
          <WhySpotifyToWhyWebEngineer />
        </motion.div>
      </ExitAnimator>
    </>
  );
};

export default WhySpotifyPage;
