export const resumeInterests = [
  {
    title: 'Genres',
    bullets: ['Melodic house', 'Analog techno', 'Midtempo', 'Dubstep'],
  },
  {
    title: 'Artists',
    bullets: ['Lane 8', 'Rezz', 'Recondite', 'Monolink', 'Rinzen'],
  },
  {
    title: 'Interests',
    bullets: ['Yoga', 'Meditation', 'Running', 'Music production', 'Coffee', 'Hiking', 'Writing'],
  },
];
