import * as React from 'react';
import TypeWriterEffect from '../../components/TypeWriter/TypeWriter';
import ArrowDownIcon from '../../components/ArrowDownIcon/ArrowDownIcon';
import { motion } from 'framer-motion';
import ExitAnimator from '../../components/ExitAnimator/ExitAnimator';
import { Helmet } from 'react-helmet';

const WelcomePage = ({ isMenuOpen }) => {
  return (
    <>
      <Helmet>
        <title>Why Spotify?</title>
      </Helmet>
      <ExitAnimator>
        <TypeWriterEffect isMenuOpen={isMenuOpen} />
      </ExitAnimator>
      <motion.div key="arrow-down" exit={{ opacity: 0 }}>
        <ArrowDownIcon />
      </motion.div>
    </>
  );
};

export default WelcomePage;
