import NativeInstrumentsImage from '../../../../assets/photos/native-instruments.jpg';
import SpotifyGreen from '../../../../assets/photos/spotify-green.jpg';
import Rinzen from '../../../../assets/photos/rinzen.jpg';
import HunzaVerse from '../../../../assets/photos/hunza-verse.jpg';
import BeyondDope from '../../../../assets/photos/beyond-dope.png';

export const edmArticles = [
  {
    heading: 'Of course, I’ve written about Spotify',
    url: 'https://edm.com/news/spotify-auto-mixing-feature',
    background: '#C6B8D6',
    color: '#4500E6',
    imageSrc: SpotifyGreen,
  },
  {
    heading: 'And, music-tech in general',
    url: 'https://edm.com/news/native-instruments-emh-partners-investment',
    background: '#E6B392',
    color: '#5F4263',
    imageSrc: NativeInstrumentsImage,
  },
  {
    heading: 'Crafted a 2000+ word feature to put the spotlight on an emerging artist',
    url: 'https://edm.com/features/the-story-of-rinzen',
    background: '#23357D',
    color: '#F37AC3',
    imageSrc: Rinzen,
  },
  {
    heading: 'Dug into Pakistan’s nascent dance music industry to clear controversy in the local community',
    url: 'https://edm.com/features/behind-controversial-pakistan-music-festival-hunza-verse',
    background: '#9FF1BD',
    color: '#23357D',
    imageSrc: HunzaVerse,
  },
  {
    heading:
      'Told the story of a newly formed non-profit organization fighting opioid abuse in the bass music community',
    url: 'https://edm.com/news/beyond-dope-productions',
    background: '#5A3E64',
    color: '#D2F574',
    imageSrc: BeyondDope,
  },
];
