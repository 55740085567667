import { motion } from 'framer-motion';
import * as React from 'react';
import styles from './WhySpotifyIntro.module.css';
import SpotifyIconGreen from '../../../../assets/photos/spotify-icon-green.png';

const WhySpotifyIntro = () => {
  return (
    <div className={styles.waveContainer}>
      <div className={styles.whySpotifyIntro}>
        <div>
          <motion.h1
            className={styles.introHeader}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={{
              visible: { color: '#1db954' },
              hidden: { color: '#efd9ce' },
            }}
            transition={{ delay: 0.5, duration: 0.7 }}
          >
            Hi, I'm Saad Masood.
          </motion.h1>
          <p>
            I’m not actively searching for new opportunities, nor am I unhappy with my current role. Becoming a part of
            Spotify is simply my dream.
          </p>
          <p>
            As a music enthusiast on a relentless pursuit fusing music and technology to improve lives, Spotify mirrors
            my values.
          </p>
          <p>
            I could go on and on about why I’m inspired by Spotify. But instead, let me show you how I’ve incorporated
            my joy for Spotify and passion for music into everything I do...
          </p>
          <div className={styles.spotifyIconContainer}>
            <motion.img
              src={SpotifyIconGreen}
              className={styles.spotifyIcon}
              // whileHover={{ scale: 1.2 }}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.4, duration: 0.7 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySpotifyIntro;
