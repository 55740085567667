import * as React from 'react';
import { resumeExperience } from './experienceData';
import ResumeSectionTitle from '../ResumeSectionTitle/ResumeSectionTitle';
import styles from './ResumeExperience.module.css';
import ResumeSkills from '../ResumeSkills/ResumeSkills';
import ResumeBulletPoints from '../ResumeBulletPoints/ResumeBulletPoints';
import ResumeRoleHeader from '../ResumeRoleHeader/ResumeRoleHeader';

const ResumeExperience = () => {
  return (
    <div className={styles.resumeExperienceSection}>
      <ResumeSectionTitle sectionTitle="EXPERIENCE" />
      {resumeExperience.map(({ company, title, location, duration, skills, bullets, withPreviousSkills }) => (
        <div className={styles.experience} key={title + company}>
          <ResumeRoleHeader title={title} subtitlePrimary={company} subtitleSecondary={location} duration={duration} />
          <ResumeSkills skills={skills} withPreviousSkills={withPreviousSkills} />
          <ResumeBulletPoints bullets={bullets} />
        </div>
      ))}
    </div>
  );
};

export default ResumeExperience;
