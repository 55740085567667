import * as React from 'react';
import styles from './MuscNotesAnimation.module.css';

const MuscNotesAnimation = ({ note1, note2, note3, note4 }) => {
  return (
    <>
      {/* Music notes animation adapted from: https://codepen.io/MaryG/pen/wJMMdw */}
      <div className={styles.musicNotesContainer}>
        <div className={styles.musicNotes}>
          {note1 && <div className={styles.note1}>&#9835; &#9833;</div>}
          {note2 && <div className={styles.note2}>&#9833;</div>}
          {note3 && <div className={styles.note3}>&#9839; &#9834;</div>}
          {note4 && <div className={styles.note4}>&#9834;</div>}
        </div>
      </div>
    </>
  );
};

export default MuscNotesAnimation;
