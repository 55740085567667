import * as React from 'react';
import ResumeSectionTitle from '../ResumeSectionTitle/ResumeSectionTitle';
import ResumeRoleHeader from '../ResumeRoleHeader/ResumeRoleHeader';
import ResumeBulletPoints from '../ResumeBulletPoints/ResumeBulletPoints';
import styles from './ResumeInterests.module.css';
import { resumeInterests } from './resumeInterestsData';

const ResumeInterests = () => {
  return (
    <div className={styles.resumeInterests}>
      <ResumeSectionTitle sectionTitle="MUSIC / INTERESTS" />
      <div className={styles.resumeInterestsFlex}>
        {resumeInterests.map(({ title, bullets }) => (
          <div className={styles.resumeInterest} key={title}>
            <ResumeRoleHeader title={title} />
            <ResumeBulletPoints bullets={bullets} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResumeInterests;
