import * as React from 'react';
import MetrifyScreenShot from '../../../../assets/photos/metrify-top-tracks-screenshot.png';
import WaveDanceScreenShot from '../../../../assets/photos/wavedance-umi-screenshot.png';
import ComingSoon from '../../../../assets/photos/coming-soon.png';
import styles from './ProjectSummary.module.css';
import { motion } from 'framer-motion';

const ProjectSummary = ({ project }) => {
  const { name, skills, bullets, url } = project;
  const imageSize = 500;

  const openProjectUrl = () => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const getImageSrc = () => {
    switch (name) {
      case 'Metrify':
        return MetrifyScreenShot;
      case 'WaveDance':
        return WaveDanceScreenShot;
      case 'SoundCloud to Spotify':
        return ComingSoon;
      default:
        return;
    }
  };

  const imageSrc = getImageSrc();

  return (
    <div className={styles.projectSummary}>
      <div className={styles.projectSummaryDetails}>
        <motion.h3
          onClick={openProjectUrl}
          whileHover={url ? { cursor: 'pointer', opacity: 0.6 } : undefined}
          initial="hidden"
          animate="visible"
          variants={{
            visible: { color: '#1db954' },
            hidden: { color: '#efd9ce' },
          }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {name}
        </motion.h3>
        <motion.div
          key={`${name}-skills`}
          className={styles.skillPillContainer}
          initial="hidden"
          animate="visible"
          variants={{
            visible: {
              transition: {
                delay: 3,
                duration: 0.5,
                staggerChildren: 0.2,
                staggerDirection: 1,
              },
            },
          }}
        >
          {skills.map(skill => (
            <motion.span
              key={skill}
              className={styles.skillPill}
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
              }}
            >
              {skill}
            </motion.span>
          ))}
        </motion.div>
        <ul>
          {bullets.map(bullet => (
            <li key={bullet}>{bullet}</li>
          ))}
        </ul>
      </div>
      <img src={imageSrc} width={imageSize} height={imageSize} res="true" alt={`Mobile screenshot for ${name}`} />
    </div>
  );
};

export default ProjectSummary;
