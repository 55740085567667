import * as React from 'react';
import Resume from '../../components/Resume/Resume';
import { Helmet } from 'react-helmet';

const ResumePage = () => {
  return (
    <>
      <Helmet>
        <title>Resume | Saad Masood</title>
      </Helmet>
      <Resume />
    </>
  );
};

export default ResumePage;
