import { motion } from 'framer-motion';
import * as React from 'react';
import styles from './ResumeSkills.module.css';

const ResumeSkills = ({ skills, withPreviousSkills, marginTop = 10 }) => {
  return skills.length ? (
    <div style={{ marginTop }}>
      {/* <span className={styles.resumeSkillsTitle}>{`Skills: `}</span> */}
      {withPreviousSkills && 'Previous skills + '}
      <motion.div
        className={styles.skillPillContainer}
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            transition: {
              delay: 3,
              duration: 0.5,
              staggerChildren: 0.2,
              staggerDirection: 1,
            },
          },
        }}
      >
        {skills.map(skill => (
          <motion.span
            key={skill}
            className={styles.skillPill}
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
          >
            {skill}
          </motion.span>
        ))}
      </motion.div>
    </div>
  ) : null;
};

export default ResumeSkills;
