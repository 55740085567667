import { motion } from 'framer-motion';
import * as React from 'react';
import styles from './WhySpotifyProjects.module.css';
import ProjectCarousel from '../ProjectCarousel/ProjectCarousel';

const WhySpotifyProjects = () => {
  return (
    <div className={styles.whySpotifyProjects}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.5, staggerChildren: 1 }}
        variants={{
          visible: { opacity: 1, scale: 1, translateY: -200 },
          hidden: { opacity: 0, scale: 1 },
        }}
      >
        <motion.div
          className="greenLine"
          style={{ display: 'flex' }}
          whileInView="visible"
          viewport={{ true: false }}
          variants={{
            visible: { width: '100%' },
            hidden: { width: 0 },
          }}
          transition={{ delay: 0.2, duration: 0.7 }}
        />
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={{
            visible: { color: '#1db954' },
            hidden: { color: '#efd9ce' },
          }}
          transition={{ delay: 0.5, duration: 0.7 }}
        >
          The essence in all my endeavors is connecting music and software to kindle delight.
        </motion.h2>
        <motion.h3
          className={styles.whyProjectsSubHeading}
          initial="hidden"
          whileInView="visible"
          variants={{
            visible: { fontStyle: 'normal', transition: { delay: 1 } },
            hidden: { fontStyle: 'normal' },
          }}
        >
          Not to mention, every app I’ve built is deeply integrated with Spotify’s API.
        </motion.h3>
        <ProjectCarousel />
      </motion.div>
    </div>
  );
};

export default WhySpotifyProjects;
