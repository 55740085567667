import { motion } from 'framer-motion';
import * as React from 'react';
import styles from './ResumeRoleHeader.module.css';

const ResumeRoleHeader = ({ title, subtitlePrimary, subtitleSecondary, duration }) => {
  const getPrimarySubtitleDisplayText = () => {
    let primarySubtitleDisplayText = subtitlePrimary;

    if (subtitleSecondary) {
      primarySubtitleDisplayText = `${primarySubtitleDisplayText}, `;
    }

    return primarySubtitleDisplayText;
  };

  return (
    <div className={styles.roleHeader}>
      <motion.div
        className={styles.roleTitle}
        initial={{ color: '#efd9ce' }}
        animate={{ color: '#1db954' }}
        transition={{ delay: 0.8, duration: 0.7 }}
      >
        {title}
      </motion.div>
      <div className={styles.subRow}>
        <div>
          <span className={styles.roleCompany}>{getPrimarySubtitleDisplayText()}</span>
          {subtitleSecondary && <span className={styles.roleLocation}>{subtitleSecondary}</span>}
        </div>
        <div className={styles.roleDuration}>{duration}</div>
      </div>
    </div>
  );
};

export default ResumeRoleHeader;
