import * as React from 'react';
import ResumeSectionTitle from '../ResumeSectionTitle/ResumeSectionTitle';
import ResumeRoleHeader from '../ResumeRoleHeader/ResumeRoleHeader';
import ResumeBulletPoints from '../ResumeBulletPoints/ResumeBulletPoints';
import ResumeSkills from '../ResumeSkills/ResumeSkills';
import styles from './ResumeProjects.module.css';
import { resumeProjects } from './resumeProjectData';

const ResumeProjects = () => {
  return (
    <div className={styles.resumeProjects}>
      <ResumeSectionTitle sectionTitle="PROJECTS" />
      {resumeProjects.map(({ title, skills, bullets }, index) => (
        <div className={styles.resumeProject} key={title}>
          <ResumeRoleHeader title={title} />
          <ResumeSkills skills={skills} marginTop={2} />
          <ResumeBulletPoints bullets={bullets} />
          {index !== resumeProjects.length - 1 && (
            <div className="greenLine" style={{ margin: '0 auto', width: '10%' }} />
          )}
        </div>
      ))}
    </div>
  );
};

export default ResumeProjects;
