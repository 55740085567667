import { motion } from 'framer-motion';
import * as React from 'react';
import styles from './ConnectedRequirementExplanation.module.css';

const ConnectedRequirementExplanation = ({ color, requirement, explanation }) => {
  const commonMotionProps = {
    initial: 'hidden',
    whileInView: 'visible',
    viewport: { once: true },
    variants: {
      visible: { opacity: 1, scale: 1 },
      hidden: { opacity: 0, scale: 1 },
    },
    transition: { duration: 0.5, staggerChildren: 1 },
  };

  return (
    <motion.div className={styles.connectedRequirementExplanation} {...commonMotionProps}>
      <motion.div className={styles.requirement}>
        <motion.p
          animate={{
            background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 65%, ${color} 65%)`,
          }}
          transition={{ delay: 0.5, duration: 0.7 }}
        >
          "{requirement}"
        </motion.p>
      </motion.div>
      <motion.div
        style={{ background: color }}
        className={styles.requirementExplanationDivider}
        {...commonMotionProps}
        variants={{
          visible: { height: 'auto' },
          hidden: { height: 0 },
        }}
        transition={{ delay: 0.3, duration: 0.7 }}
      />
      <motion.div className={styles.explanation}>
        <ul>
          {explanation.map(e => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </motion.div>
    </motion.div>
  );
};

export default ConnectedRequirementExplanation;
