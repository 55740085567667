import * as React from 'react';
import ResumeSectionTitle from '../ResumeSectionTitle/ResumeSectionTitle';
import ResumeRoleHeader from '../ResumeRoleHeader/ResumeRoleHeader';
import ResumeBulletPoints from '../ResumeBulletPoints/ResumeBulletPoints';
import styles from './ResumeEducation.module.css';

const ResumeEducation = () => {
  return (
    <div className={styles.resumeEducation}>
      <ResumeSectionTitle sectionTitle="EDUCATION" />
      <ResumeRoleHeader
        title="New York University"
        subtitlePrimary="B.A in Computer Science & Economics"
        duration="Dec 2019"
      />
      <ResumeBulletPoints bullets={['Minors in Business & Music', 'GPA 3.5', "Dean's List"]} />
    </div>
  );
};

export default ResumeEducation;
