import { motion } from 'framer-motion';
import * as React from 'react';
import { edmInterviewPhotos } from './edmInterviewPhotos.js';
import styles from './EdmInterviewPhotoGallery.module.css';

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      delay: 5,
      staggerChildren: 0.5,
      staggerDirection: 1,
    },
  },
};

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
};

const EdmInterviewPhotoGallery = () => {
  const imageSize = 170;

  const openLinkInNewTab = link => {
    window.open(link, '_blank');
  };

  return (
    <motion.div
      initial="closed"
      whileInView="open"
      viewport={{ once: true }}
      variants={sideVariants}
      className={styles.edmInterviewPhotoGallery}
    >
      {edmInterviewPhotos.map(({ url, imageSrc }) => (
        <motion.img
          key={url}
          whileHover={{ scale: 1.1, cursor: 'pointer' }}
          variants={itemVariants}
          src={imageSrc}
          width={imageSize}
          height={imageSize}
          onClick={() => openLinkInNewTab(url)}
        />
      ))}
    </motion.div>
  );
};

export default EdmInterviewPhotoGallery;
