export const resumeProjects = [
  {
    title: 'Metrify',
    skills: ['Express', 'MongoDB', 'Handlebars', 'Bootstrap', 'Heroku', 'Spotify API', 'REST APIs', 'Git'],
    bullets: [
      'Web app for Spotify users to view their top played tracks & artists, sourcing data directly from Spotify’s Personalization API via responsive, user-friendly UI',
      'Filter top tracks/artists by time (all time, last 6 months, last month)',
      'Visualize audio features(e.g. danceability, instrumentalness, energy)',
      'Create playlists of personalized top played tracks directly into Spotify account',
    ],
  },
  {
    title: 'WaveDance',
    skills: [
      'React Native',
      'Expo',
      'Firebase',
      'Spotify API',
      'Lottie Animation',
      'TypeScript',
      'eslint',
      'Prettier',
      'husky',
      'Git',
    ],
    bullets: [
      'Cross-platform mobile app to connect, chat, and meet up with people at over 3000 dance music events in over 100 locations across the U.S. and Canada',
      'Rich user profiles: deep integration with Spotify API to embed personalized music content, music-related prompts, favorite genres, passions',
      'Safety features to block, report, and ignore users',
    ],
  },
  {
    title: 'SoundCloud to Spotify',
    skills: ['React', 'TypeScript', 'Spotify API', 'Chrome API', 'Git'],
    bullets: [
      'Google Chrome extension to seamlessly transfer SoundCloud playlists, albums, and songs directly into your Spotify account',
    ],
  },
];
