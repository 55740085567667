export const resumeExperience = [
  {
    company: 'Catchpoint Systems',
    title: 'Software Engineer III',
    location: 'New York, NY',
    duration: 'Dec 2021 - Present',
    skills: ['Octopus Deploy', 'Jenkins', 'Kibana'],
    withPreviousSkills: true,
    bullets: [
      'Automated user sign ups, purchases, and cancelations via HubSpot API to relieve engineering and marketing teams from manually updating HubSpot via shared Excel sheet',
      'Leading Chargify API architecture, estimation, and integration to streamline subscription management, grow business, enhance buyer experience, and save time',
      'Technical oversight of WebPageTest SaaS module ahead of Beta release (high business value feature)',
      'Craft intuitive analytics web interfaces that hide the complexity of backend systems and proprietary NoSQL database',
      'Conduct engineering design of large features, code reviews for quality, consistency, and correctness',
      'Scope, size and assign user stories to squad members',
      'Identify and clarify feature requirements and gaps with Product and UX teams',
    ],
  },
  {
    company: 'Catchpoint Systems',
    title: 'Software Engineer II',
    location: 'New York, NY',
    duration: 'Oct 2020 - Dec 2021',
    skills: ['Styled Components', 'Azure DevOps', 'Figma'],
    withPreviousSkills: true,
    bullets: [
      'Proactively minimized rendering time by up to 88% before GA launch (as measured by React DevTools Profiler and Chrome DevTools Performance) through memorization, feature-specific refactoring, viewport-based rendering',
      'Reduced squad defects by 72% over 4 release cycles by developing and implementing a quality improvement process',
      'Technical mentorship of lower-ranking engineers (e.g. coding best practices, design paradigms, domain knowledge, product understanding)',
      'Conducted technical interviews for front end engineering candidates',
      'Onboarded & guided interns',
      'Collaborated with QA to define test plans and automated testing strategies',
      'Performed Triage for Defects escalated to engineering',
    ],
  },
  {
    company: 'Catchpoint Systems',
    title: 'Software Engineer',
    location: 'New York, NY',
    duration: 'Feb 2020 - Oct 2020',
    skills: ['VersionOne', 'React Testing Library'],
    withPreviousSkills: true,
    bullets: [
      'Developed and launched Explorer feature to GA: empowering users to perform multi-dimensional analysis of raw Synthetic and Real User data to quickly discover causes for performance anomalies',
      'Revamped data source selection logic and UI from flat, table-based single selection to hierarchal, tree-based selection across 18+ source types',
      'Built publicly accessible performance analysis page with security validations on public URL access',
    ],
  },
  {
    company: 'Catchpoint Systems',
    title: 'Full Stack Engineering Intern',
    location: 'New York, NY',
    duration: 'June 2019 - Dec 2019',
    skills: [
      '.NET Core',
      'C#',
      'Entity Framework',
      'React',
      'HTML',
      'TypeScript',
      'SCSS',
      'GraphQL',
      'REST APIs',
      'xUnit',
      'Jest',
      'Enzyme',
      'Git',
    ],
    withPreviousSkills: false,
    bullets: [
      'Immersed in all parts of software development: from querying databases and creating APIs on server-side to creating custom styled data-driven UIs on client-side',
      'Applied viewport loading on a heavily used web page to speed up initial page load, reduce API calls by 30% and data transfer by 77%',
      'Built a data-rich, internal tooling feature within large web app, including filters, dynamic client-side text file generation, unit tests and stories. Converted 70% of system administrators from outdated, single-user desktop app to modern, multi-user internal web tool',
      'Designed and built internal SPA to view, search and selectively delete keys in Redis cache - relieved entire front end team from querying Redis through command line',
    ],
  },
  {
    company: 'EDM.com',
    title: 'Contributing Writer & Curator',
    location: 'New York, NY',
    duration: 'March  2017 - Present',
    skills: [],
    withPreviousSkills: false,
    bullets: [
      'Interviewed over 100 artists & composed over 250 pieces',
      'Coined and lead monthly house and techno feature The House Brew',
      'Review tracks, curate playlists, promote marketing campaigns and craft feature stories around electronic music and its culture',
      'Collaborate with fellow writers, the editorial team, and through external partnerships',
    ],
  },
];
