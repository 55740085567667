import * as React from 'react';
import { motion } from 'framer-motion';
import styles from './WhySpotifyArticles.module.css';
import EdmArticlesTimeline from '../EdmArticlesTimeline/EdmArticlesTimeline';

const WhySpotifyArticles = () => {
  return (
    <div className={styles.whySpotifyArticles}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.5, staggerChildren: 1 }}
        variants={{
          visible: { opacity: 1, scale: 1, translateY: -200 },
          hidden: { opacity: 0, scale: 1 },
        }}
      >
        <motion.div
          className="greenLine"
          style={{ display: 'flex' }}
          whileInView="visible"
          viewport={{ true: false }}
          variants={{
            visible: { width: '100%' },
            hidden: { width: 0 },
          }}
          transition={{ delay: 0.2, duration: 0.7 }}
        />
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={{
            visible: { color: '#1db954' },
            hidden: { color: '#efd9ce' },
          }}
          transition={{ delay: 0.5, duration: 0.7 }}
        >
          When I’m not writing code to chase my passion for music, I’m writing words to shine a light on artists and
          music industry organizations I believe in, having written over 250 pieces for EDM.com
        </motion.h2>
        <EdmArticlesTimeline />
      </motion.div>
    </div>
  );
};

export default WhySpotifyArticles;
