import * as React from 'react';
import styles from './ResumeSectionTitle.module.css';
import { motion } from 'framer-motion';

const ResumeSectionTitle = ({ sectionTitle }) => {
  return (
    <div>
      <h2 className={styles.resumeSectionTitle}>{sectionTitle}</h2>
      <motion.div
        className={styles.greenLine}
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ delay: 0.3, duration: 0.7 }}
      />
    </div>
  );
};

export default ResumeSectionTitle;
