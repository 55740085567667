import * as React from 'react';
import ResumeEducation from '../ResumeEducation/ResumeEducation';
import ResumeExperience from '../ResumeExperience/ResumeExperience';
import ResumeHeader from '../ResumeHeader/ResumeHeader';
import ResumeInterests from '../ResumeInterests/ResumeInterests';
import ResumeProjects from '../ResumeProjects/ResumeProjects';
import styles from './Resume.module.css';

const Resume = () => {
  return (
    <>
      <ResumeHeader />
      <div className={styles.resume}>
        <ResumeExperience />
        <div className={styles.resumeEducationProjects}>
          <ResumeEducation />
          <ResumeProjects />
          <ResumeInterests />
        </div>
      </div>
    </>
  );
};

export default Resume;
