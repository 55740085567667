import * as React from 'react';
import NavigationMenu from './components/NavigationMenu/NavigationMenu';
import { AnimatePresence, useCycle } from 'framer-motion';
import ResumePage from './pages/ResumePage/ResumePage';
import { useLocation, Route, Routes, Navigate } from 'react-router-dom';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import WhySpotifyPage from './pages/WhySpotifyPage/WhySpotifyPage';
import WhyWebEngineerPage from './pages/WhyWebEngineerPage/WhyWebEngineerPage';

const App = () => {
  const location = useLocation();
  const [isMenuOpen, cycleOpen] = useCycle(false, true);

  React.useEffect(() => {
    document.body.scrollTop = 0;
  }, [location.pathname]);

  return (
    <>
      <NavigationMenu open={isMenuOpen} cycleOpen={cycleOpen} />
      <AnimatePresence exitBeforeEnter initial={true}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/why-spotify" element={<WhySpotifyPage />} />
          <Route path="/why-web-engineer-personalization" element={<WhyWebEngineerPage />} />
          <Route path="/resume" element={<ResumePage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default App;
