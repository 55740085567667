import * as React from 'react';
import { motion } from 'framer-motion';
import styles from './WhySpotifyToWhyWebEngineer.module.css';
import { BsArrowRightCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const WhySpotifyToWhyWebEngineer = () => {
  const navigate = useNavigate();

  const onArrowRightClick = () => {
    navigate('/why-web-engineer-personalization');
  };

  return (
    <div className={styles.whySpotifyToWhyWebEngineer}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.5, staggerChildren: 1 }}
        variants={{
          visible: { opacity: 1, scale: 1, translateY: -200 },
          hidden: { opacity: 0, scale: 1 },
        }}
      >
        <motion.div
          className="greenLine"
          style={{ display: 'flex' }}
          whileInView="visible"
          viewport={{ once: true }}
          variants={{
            visible: { width: '100%' },
            hidden: { width: 0 },
          }}
          transition={{ delay: 0.2, duration: 0.7 }}
        />
        <h2>
          Now that my commitment to music and music-tech is clear, let me tell you why I’m a perfect fit for the Web
          Engineer role on Spotify’s Personalization team.
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            transition={{ delay: 0.7, duration: 0.6 }}
          >
            <BsArrowRightCircle
              size={50}
              className={styles.arrowRightIcon}
              onClick={onArrowRightClick}
              color="#1db954"
            />
          </motion.span>
        </h2>
      </motion.div>
    </div>
  );
};

export default WhySpotifyToWhyWebEngineer;
